<template>
<!--  <AddNewButton v-if="!isLoading" :label="$t('Add')" @click="addNewItem()" class="p-mt-4"/>-->
  <Toolbar v-if="user" class="p-mb-4 p-mt-4">
    <template #start>
      <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem(false)" />
    </template>
    <template #end>
<!--      <div class="p-input-icon-left">-->
<!--        <i class="pi pi-search" />-->
<!--        <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--      </div>-->
      <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
    </template>
  </Toolbar>
  <Spinner v-if="isLoading"></Spinner>
  <DesktopDataTable v-else
                    class="p-mt-4"
                    tableName="Customer orders"
                    :dataTableIsLoading="dataTableIsLoading"
                    :itemsArrayIsEmpty="itemsArrayIsEmpty"
                    :sort="sort"
                    :showSearchInput="false"
                    :actionButtonsCount="actionButtonsCount"
                    @sort-items="sortItems"
                    :headers="[
              {name: 'number', title: 'Number', width: '7%', sortable: true},
              {name: 'car', title: 'Car data', width: '12%', sortable: false},
              {name: 'order', title: 'Order data', width: '20%', sortable: false},
              {name: 'masters', title: 'Masters', width: '10%', sortable: false},
              {name: 'grand_total', title: 'Amount', width: '8%', sortable: true},
              {name: 'state', title: 'State', width: '9%', sortable: true},
              {name: 'start', title: 'Start', width: '10%', sortable: true},
              {name: 'archived_at', title: 'Archived', width: '16%', sortable: true},
              {name: 'mileage', title: 'Mileage', width: '8%', sortable: false},
              // {name: '', title: '',  width: '45px', sortable: false},
          ]">
    <template v-slot:body>
      <template v-for="(item, index) of items" :key="index">
        <tr :class="{'updated-item': isItemUpdated(item.id)}">
          <td>
            <div>{{ item.number }}</div>
            <div v-if="item.parentOffer?.number" class="p-pt-1" style="font-size: 0.85em">
              <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">
                <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>
                <span>{{ item.parentOffer.number }}</span>
              </router-link>
            </div>
            <div v-else-if="item.parentOrder?.number" class="p-pt-1" style="font-size: 0.85em">
              <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">
                <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>
                <span>{{ item.parentOrder.number }}</span>
              </router-link>
            </div>
          </td>
          <td>
             <span v-if="item.car">
              <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank" class="p-d-flex p-flex-wrap">
                <i class="ti-car p-mr-1"></i>
                <div class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</div>
                <div class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</div>
              </router-link>
              <div class="table-small-text">
                <span>{{ item.car.plate_number }}</span>
                <span v-if="item.car.year">, {{ item.car.year }}</span>
              </div>
            </span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>
            <div>
              <a href="#" @click.prevent="toggleShowPositionTable(item)">
                <i style="font-size: 0.9em" class="p-mr-1 pi" :class="item.showPositionsTable ? 'pi-minus': 'pi-plus'"></i>
                <span class="text">{{ item.name || item.number }}</span>
              </a>
              <span @click="toggleWarning(item)" v-show="item.staff_comment" class="p-link icon p-ml-2">
                <i class="ti-comment-alt" :class="{'warning-color':item.is_warning}"></i>
              </span>
            </div>
            <div class="table-small-text p-mt-1">
<!--              <span :class="{'order-created-by-customer':item.is_created_by_customer}">-->
<!--                {{ $t('Created') }}:-->
<!--              <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>-->
<!--                <span v-if="item.creator" class="table-date-line">-->
<!--                  <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
<!--                  <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                </span>-->
<!--              </span>-->
              <span :class="{'order-created-by-customer':item.is_created_by_customer}">
                <span>{{ $t('Created') }}: </span>
                <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                <span v-if="item.creator">
                  <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>
                  <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                </span>
              </span>
            </div>
          </td>
          <td class="avatar-td">
            <AvatarsCell :masters="item.masters"/>
          </td>
          <td>
<!--            <span v-if="item.positions && item.positions.filter(position => position.status === 1).length">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + ((position.sell_price - ((position.discount / 100) * position.sell_price)) * position.qty) * (((position.tax ? position.tax.value : 0) / 100) + 1), 0) + (item.consumable ? ((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) * ((item.consumable.tax.value / 100) + 1) : 0)) }}</span>-->
<!--            <span v-else>{{ formatCurrency(0) }}</span>-->
            <span>{{ formatCurrency(item.grand_total) }}</span>
            <template v-if="item.is_invoiced && item.unpaidSum && +item.unpaidSum">
              <div style="font-size: 0.8rem" class="warning-color">
                Unpaid: {{ formatCurrency(item.unpaidSum) }}
              </div>
            </template>
            <template v-else-if="!item.is_invoiced && (item.state === 7 || item.state === 8) && +item.grand_total">
              <div style="font-size: 0.8rem" class="warning-color">
                Unpaid: {{ formatCurrency(item.grand_total) }}
              </div>
            </template>
          </td>
          <td>
            <OrderStateButton :item="item"/>
          </td>
          <td>
            <span v-if="item.start">{{ formatDate(item.start) }}</span>
          </td>
          <td>
            <span v-if="item.is_archived" class="table-small-text p-mt-1">
              <span v-if="item.archived_at">{{ formatDate(item.archived_at) || '' }}, </span>
              <span v-if="item.archivedBy" class="table-date-line">
                <span v-if="item.archivedBy.type === constants.userTypes.legal_entity && item.archivedBy.company_name">{{ item.archivedBy.company_name }}<span> ({{ item.archivedBy.first_name }} {{ item.archivedBy.last_name }})</span></span>
                <span v-else>{{ item.archivedBy.first_name }} {{ item.archivedBy.last_name }}</span>
              </span>
            </span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>
            <span v-if="item.mileage">{{ formatMileage(item.mileage) }} {{ $t(settings.measurements.mileage) }}</span>
            <i v-else class="ti-minus"></i>
          </td>
<!--          <td>-->
<!--            <OrderDocumentsButton :orderData="item"/>-->
<!--          </td>-->
          <td>
            <div class="p-d-flex">
              <router-link :to="{ path: `/orders/${item.number}` }"><ViewButton/></router-link>
  <!--              <EditButton :disabled="item.archived_at && ($store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant)"-->
  <!--                          @click="editItem(item)" />-->
  <!--              <DeleteButton @click="confirmItemDelete(item)"-->
  <!--                            v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
            </div>
          </td>
        </tr>
        <tr v-show="item.showPositionsTable" class="table-opened-info">
          <td colspan="12" style="padding: 0 !important; margin-bottom: 20px !important;">
            <PositionsTable :item="item" :isSpoilerTable="true" @update-items="updateItems"/>
          </td>
        </tr>
      </template>
    </template>
  </DesktopDataTable>

  <OwnPaginator v-show="!itemsArrayIsEmpty"
                :rows="pagination.perPage"
                :currentPage="pagination.currentPage - 1"
                :totalPages="pagination.pageCount"
                :totalRecords="pagination.totalCount"
                :rowsPerPageOptions="[5,10,25,50,100,200]"
                :pageLinkSize="11">
  </OwnPaginator>

  <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

  <OrderModal :visible="itemModal"
              :item="item"
              :masters="masters"
              :responsibles="responsibles"
              :taxes="taxes"
              :services="services"
              :suppliers="suppliers"
              :areas="areas"
              :warehouseItems="warehouseItems"
              @update-items="updateItems"
              @close="closeItemModal">
  </OrderModal>

</template>

<script>
import httpClient from '@/services/http.services'
import RequestPositionStates from "@/translations/states/RequestPositionStates";
import OrderModal from '@/pages/orders/components/OrderModal'
import OrderStateButton from '@/pages/orders/components/OrderStateButton'
import PositionsTable from '@/components/PositionsTable'
import httpMixins from '@/mixins/httpMixins'
import formatMixins from '@/mixins/formatMixins'
import datatableMixins from "@/mixins/datatableMixins";
// import OrderDocumentsButton from "@/pages/orders/components/OrderDocumentsButton";
import AvatarsCell from "@/components/AvatarsCell";

export default {
  mixins: [ httpMixins, formatMixins, datatableMixins ],
  components: { OrderModal, OrderStateButton, PositionsTable, AvatarsCell },
  emits: ['update-items', 'change-customer-status'],
  props: {
    user: Object,
  },
	data() {
		return {
      // warehouseItems: null,
      // masters: null,
      // responsibles: null,
      // taxes: null,
      // services: null,
      // suppliers: null,
      // areas: null,
      fieldsExpand: 'responsible,customer,creator,archivedBy,masters,positions,positions.history,positions.request_state_history,consumable,car,area,recommendation_history,state_history,comments_history,parentOffer,parentOrder,unpaidSum',
      apiLink: 'order/get-customer-orders',
      // sort: {
      //   column: 'id',
      //   direction: '-',
      // },
      RequestPositionStates,
		}
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('customers') === -1 || route.indexOf('orders') === -1) {
        return false
      }
      this.checkRouteQuery()
      this.getItems()
    },
  },
  mounted() {
    // if (this.$store.state.user?.role || !this.$route.params.id) return false
    if (!this.$route.params.id) return false
    this.loadData()
  },
  methods: {
    // updateOrder(item, summary, customer, car) {    // DODELAT'!!
    //   if (item.isNew) return false
    //   let filteredItem = this.items.filter(i => i.id === item.id)[0]
    //   // this.items = this.items.filter(i => i.id !== item.id)
    //   if (filteredItem) {
    //     // this.items.push(item)
    //     // console.log(filteredItem)
    //     // console.log(filteredItem.customer ,item.customer)
    //     filteredItem.name = item.name
    //     filteredItem.state = item.selectedState?.num
    //     filteredItem.customer = item.selectedCustomer ?? null
    //
    //     filteredItem.grand_total = +summary.grand_total
    //     filteredItem.customer = customer
    //     filteredItem.car = car
    //   }
    // },
    loadData() {
      this.checkRouteQuery()
      this.getItems()

      this.getModalData()
      // this.getTaxes()
      // this.getMasters()
      // this.getResponsibles()
      // this.getServices()
      // this.getSuppliers()
      // this.getAreas()
      // this.getWarehouseItems()
    },
    async toggleWarning(itemId) {
      const item = this.items.filter(item => item.id === itemId)[0]
      const value = !item.warning
      try {
        const { status } = await httpClient.post('order/toggle-warning', { id: itemId, state: value })
        if (status === 200) {
          item.warning = !item.warning
        }
      } catch(err) {
        this.showError(err)
      }
    },
    addNewItem(time = false) {
      this.item = {}
      if (this.user) {
        this.item.customer = this.user
        this.item.customerDisabled = true
      }

      if (time) {
        this.item.start = time
      }

      this.submitted = false
      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { customerId: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: { customerId: this.$route.params.id }
        })
        if (status === 200) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (this.openedItemsIds.length) {
            this.items.forEach(item => {
              if (this.openedItemsIds.indexOf(item.id) !== -1) {
                item.showPositionsTable = true
              }
            })
          }

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.disableEditButton = false
        this.dataTableIsLoading = false
      }
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.masters && this.responsibles && this.taxes && this.services && this.suppliers && this.areas)
    },
    itemToDeleteData() {
      return 'delete data'
    },
    actionButtonsCount() {
      // if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin || this.$store.state.user.role === constants.userRoles.accountant) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 1
    },
  }
}
</script>