<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ item?.id ? $t('Supplier editing') : $t('Adding a new supplier') }}</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="company_name" :class="{'p-invalid' : submitted && !supplierData.company_name}" v-model.trim="supplierData.company_name" autocomplete="new-password"/>
          <label for="company_name">{{ $t('Company name') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !supplierData.company_name">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="supplier_name" v-model.trim="supplierData.name" autocomplete="new-password"/>
          <label for="supplier_name">{{ $t('Supplier name') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="supplier_reg_number" :class="{'p-invalid' : submitted && !supplierData.reg_number}" v-model.trim="supplierData.reg_number" autocomplete="new-password"/>
          <label for="supplier_reg_number">{{ $t('Reg. number') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !supplierData.reg_number">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="supplier_vat_number" v-model.trim="supplierData.vat_number" autocomplete="new-password"/>
          <label for="supplier_vat_number">{{ $t('VAT number') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="phone-inputs-wrapper">
          <div class="p-float-label">
            <CustomDropdown id="customer_phone_code"
                                class="phone-code"
                                :class="{'p-invalid' : submitted && !selectedPhoneCode}"
                                v-model="selectedPhoneCode"
                                :clearSearchData="!visible"
                                :options="phoneCodesArray"
                                :filter="true"
                                optionLabel="country"
                                :filterFields="['country','value']"
                                :showClear="false"
                                autocomplete="new-password">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <template v-if="slotProps.value.flagCode">
                    <span :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                    <span>{{ slotProps.value.value }}</span>
                  </template>
                  <template v-else>
                    <span>{{ slotProps.value.value }}</span>
                  </template>
                </div>
                <span v-else style="visibility: hidden">.</span>
              </template>
              <template #option="slotProps">
                <div class="p-d-flex p-ai-center p-jc-between">
                  <div>
                    <span v-if="slotProps.option.flagCode" :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                    <span >{{ slotProps.option.country }}</span>
                  </div>
                  <div style="font-weight: 500">{{ slotProps.option.value }}</div>
                </div>
              </template>
            </CustomDropdown>
          </div>
          <div class="p-float-label p-input-icon-right">
            <i class="pi pi-phone" />
            <InputText
                id="supplier_number_phone"
                class="phone-number"
                v-model="supplierData.phone_number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                autocomplete="new-password"/>
            <label for="supplier_number_phone">{{ $t('Phone') }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label p-input-icon-right">
          <i class="ti-email" />
          <InputText id="supplier_mail" @input="validateEmail($event.target.value)" v-model.trim="supplierData.email" autocomplete="new-password"/>
          <label for="supplier_mail">{{ $t('Email') }}</label>
        </div>
        <small class="p-invalid" v-if="submitted && supplierData.email && !emailIsValid">{{ $t('Value is not a valid email address') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="supplier_bank_name" v-model.trim="supplierData.bank_name" autocomplete="new-password"/>
          <label for="supplier_bank_name">{{ $t('Bank name') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="supplier_bank_account" v-model.trim="supplierData.bank_account" autocomplete="new-password"/>
          <label for="supplier_bank_account">{{ $t('Bank account') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="supplier_days_to_pay" v-model="supplierData.days_to_pay" :min="0" :max="60" showButtons autocomplete="new-password"/>
          <label for="supplier_days_to_pay">{{ $t('Days to pay') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="supplier_tax"
                    v-model="selectedTax"
                    :options="taxes"
                    :preventFilter="true"
                    :filter="false"
                    optionLabel="value"
                    :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{slotProps.value.value}}% ({{ slotProps.value.name }})</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}% ({{ slotProps.option.name }})</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="supplier_tax">{{ $t('Tax') }}</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label p-input-icon-right">
          <i class="ti-comment-alt" />
          <Textarea id="supplier_comment" v-model="supplierData.comment" rows="3" :autoResize="true" autocomplete="new-password"/>
          <label for="supplier_comment">{{ $t('Comment') }}</label>
        </div>
      </div>
    </div>
<!--    <div class="p-formgrid p-grid">-->
<!--      <div class="p-field p-col">-->
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea id="supplier_comment" v-model="supplierData.comment" rows="3" :autoResize="true" autocomplete="new-password"/>-->
<!--          <label for="supplier_comment">{{ $t('Comment') }}</label>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
  <SupplierExistsModal :visible="supplierExistsModal"
                        :supplierId="supplierData?.id"
                        :items="foundSuppliers"
                        @select-existing-supplier="selectExistingSupplier"
                        @close="closeSupplierExistsModal"/>
</template>

<script>
import httpClient from '@/services/http.services'
import phoneCodes from '@/translations/phoneCodes'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";
import formatMixins from "@/mixins/formatMixins";
import SupplierExistsModal from "@/pages/data_directory/components/SupplierExistsModal";

export default {
  components: {SupplierExistsModal},
  mixins: [ httpMixins, formatMixins ],
  emits: ['close', 'update-items', 'update-item'],
  name: 'SupplierModal',
  props: {
    item: Object,
    itemAddress: Object,
    visible: Boolean,
    taxes: Array,
    modalLayer: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      supplierExistsModal: false,
      dataIsSending: false,
      disableSaveButton: false,
      supplierData: {},
      submitted: false,
      // taxes: [],
      selectedTax: null,
      emailIsValid: false,
      selectedPhoneCode: null,
      defaultPhoneCode: null,
      phoneCodesArray: [],
      foundSuppliers: [],
      // phoneCodes: phoneCodes.map(code => {
      //   return {
      //     code: code.code,
      //     country: `${code.countryName} (${code.originalCountryName})`,
      //     flagCode: code.flagCode
      //   }
      // }),
    }
  },
  mounted() {
    this.createPhoneCodesArray()
  },
  watch: {
    item(value) {
      this.createItemData(value)
    },
    visible() {
      // if (this.visible) {
      //   this.getTaxes()
      // }

      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
      }
    },
    '$store.state.secondLayerIsOpened'(value) {
      if (this.modalLayer !== 1) return false
      if (!value) {
        if (this.supplierExistsModal) {
          this.closeSupplierExistsModal()
        }
      }
    },
    '$store.state.thirdLayerIsOpened'(value) {
      if (this.modalLayer !== 2) return false
      if (!value) {
        if (this.supplierExistsModal) {
          this.closeSupplierExistsModal()
        }
      }
    }
  },
  methods: {
    createItemData(value) {
      this.supplierData = { ...value }
      if (value.email) {
        this.validateEmail(value.email)
      }

      if (value.phone_code) {
        const selectedPhoneCode = this.searchPhoneCode(value.phone_code)
        if (selectedPhoneCode) {
          this.selectedPhoneCode = selectedPhoneCode
        }
      } else {
        this.selectedPhoneCode = this.defaultPhoneCode
      }

      if (value.tax_id && this.taxes && this.taxes.length) {
        this.selectedTax = this.taxes.find(tax => tax.id === value.tax_id) ?? null
      } else {
        this.selectedTax = null
      }
    },
    selectExistingSupplier(supplierData) {
      this.createItemData(supplierData)
    },
    closeSupplierExistsModal() {
      this.supplierExistsModal = false
      // this.$store.commit('closeRequiredAppLayer')
      if (this.modalLayer === 2) {
        if (this.$store.state.thirdLayerIsOpened) {
          this.$store.commit('toggleThirdLayer', false)
        }
      } else if (this.modalLayer === 1) {
        if (this.$store.state.secondLayerIsOpened) {
          this.$store.commit('toggleSecondLayer', false)
        }
      }
    },
    searchPhoneCode(phoneCode) {
      if (!phoneCode) return false
      let selectedPhoneCode = null

      for(let i = 0; i < phoneCodes.length; i++) {
        const code = phoneCodes[i]
        if (code[2] === phoneCode) {
          selectedPhoneCode = {
            value: code[2],
            country: code[0],
            flagCode: code[1]
          }
          break
        }
      }
      return selectedPhoneCode
    },
    createPhoneCodesArray() {
      this.phoneCodesArray = phoneCodes.map(code => {
        if (code[2] === settings.phoneCodes.default) {
          this.defaultPhoneCode = {
            value: code[2],
            country: code[0],
            flagCode: code[1]
          }
        }

        return {
          value: code[2],
          country: code[0],
          flagCode: code[1]
        }
      })
    },
    // async getTaxes() {
    //   try {
    //     const { data, status } = await httpClient('tax/get-taxes')
    //     if (status === 200) {
    //       this.taxes = data
    //
    //       let tax;
    //       if (this.supplierData.tax_id) {
    //         tax = data.filter(tax => tax.id === this.supplierData.tax_id)[0]
    //       } else {
    //         tax = null
    //       }
    //       this.selectedTax = tax ? tax : data.filter(tax => tax.is_default === 1)[0]
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
    async saveItem() {
      this.submitted = true
      if (!this.supplierData.company_name || !this.supplierData.reg_number || (this.supplierData.email && !this.emailIsValid)) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const supplier = {
        id: this.supplierData.id ?? null,
        company_name: this.supplierData.company_name ? this.supplierData.company_name.trim() : null,
        name: this.supplierData.name ? this.supplierData.name.trim() : null,
        reg_number: this.supplierData.reg_number ? this.supplierData.reg_number.trim() : null,
        vat_number: this.supplierData.vat_number ? this.supplierData.vat_number.trim() : null,
        bank_name: this.supplierData.bank_name ? this.supplierData.bank_name.trim() : null,
        bank_account: this.supplierData.bank_account ? this.supplierData.bank_account.trim() : null,
        email: this.supplierData.email ? this.supplierData.email.trim() : null,
        phone_code: this.supplierData.phone_number && this.selectedPhoneCode ? this.selectedPhoneCode.value : null,
        phone_number: this.supplierData.phone_number && this.selectedPhoneCode ? this.supplierData.phone_number : null,
        days_to_pay: this.supplierData.days_to_pay ? this.supplierData.days_to_pay : null,
        tax_id: this.selectedTax ? this.selectedTax.id : null,
        comment: this.supplierData.comment ? this.supplierData.comment.trim() : null,
        isNew: false,
      }

      if (this.supplierData.id) {
        try {
          const { status, data } = await httpClient.post(`supplier/update-supplier`, supplier)
          if (status === 200 && data?.success) {
            if (data.found_suppliers) {
              this.supplierExistsModal = true
              this.$store.commit('openRequiredAppLayer')
              this.foundSuppliers = data.found_suppliers
            } else {
              this.supplierData.isNew = false
              // this.$emit('update-items', this.supplierData.id)
              this.$emit('update-items', this.supplierData.id)
              // this.$emit('update-item', this.supplierData)
              this.$emit('update-item', supplier)
              this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
              this.close()
            }
          } else if (data?.error) {
            if (data.error.detail === 'Registration number has already been taken.') {
              this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Registration number has already been taken'), life: settings.toastLife})
            }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`supplier/create-supplier`, supplier)
          if (status === 201 && data?.success) {
            if (data.found_suppliers) {
              this.supplierExistsModal = true
              this.$store.commit('openRequiredAppLayer')
              this.foundSuppliers = data.found_suppliers
            } else {
              this.supplierData.id = data.id
              supplier.id = data.id
              supplier.isNew = true
              // this.supplierData.isNew = true
              // this.$emit('update-items', this.supplierData.id)
              // this.$emit('update-item', this.supplierData)
              this.$emit('update-items', this.supplierData.id)
              this.$emit('update-item', supplier)
              this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: settings.toastLife});
              this.close()
            }
          } else if (data?.error) {
            if (data.error.detail === 'Registration number has already been taken.') {
              this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Registration number has already been taken'), life: settings.toastLife})
            }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailIsValid = re.test(email)
    }
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>